export const ROUTE_ICONS_CONFIG: RouteItemIcon[] = [
  {
    route: '/admin',
    iconUrl: 'assets/images/home_nav_icon.svg',
    activeIconUrl: 'assets/images/home_nav_active_icon.svg',
    active: false,
    hovered: false,
    role: ['Content Manager', 'Admin', 'Super Admin', 'Team Lead'],
  },
  {
    route: '/admin/my-team',
    iconUrl: 'assets/images/my_team_nav_icon.svg',
    activeIconUrl: 'assets/images/my_team_nav_active_icon.svg',
    active: false,
    hovered: false,
    role: ['Team Lead', 'Admin', 'Super Admin'],
  },
  {
    route: '/admin/learning-plan',
    iconUrl: 'assets/images/learning_plan_nav_icon.svg',
    activeIconUrl: 'assets/images/learning_plan_nav_active_icon.svg',
    active: false,
    hovered: false,
    role: ['Team Lead', 'Admin', 'Super Admin'],
  },
  {
    route: '/admin/learning-performance',
    iconUrl: 'assets/images/content_manager_my_courses_nav_icon.svg',
    activeIconUrl: 'assets/images/content_manager_my_courses_nav_active_icon.svg',
    active: false,
    hovered: false,
    role: ['Super Admin', 'Admin'],
  },
  {
    route: '/admin/management',
    iconUrl: 'assets/images/admin_management_icon.svg',
    activeIconUrl: 'assets/images/admin_management_icon_active.svg',
    active: false,
    hovered: false,
    role: ['Super Admin', 'Admin'],
  },
  {
    route: '/admin/create-course',
    iconUrl: 'assets/images/create_course_icon.svg',
    activeIconUrl: 'assets/images/create_course_icon_active.svg',
    active: false,
    hovered: false,
    role: ['Content Manager', 'Admin', 'Super Admin'],
  },
  {
    route: '/admin/my-courses',
    iconUrl: 'assets/images/content_manager_my_courses_nav_icon.svg',
    activeIconUrl: 'assets/images/content_manager_my_courses_nav_active_icon.svg',
    active: false,
    hovered: false,
    role: ['Content Manager', 'Admin', 'Super Admin'],
  },
  {
    route: '/admin/branding',
    iconUrl: 'assets/images/branding_nav_icon.svg',
    activeIconUrl: 'assets/images/branding_nav_icon_active.svg',
    active: false,
    hovered: false,
    role: ['Admin', 'Super Admin'],
  },
];

export interface RouteItemIcon {
  route: string;
  iconUrl: string;
  activeIconUrl: string;
  active: boolean;
  hovered: boolean;
  role: string[];
}
