import { Component, OnInit } from '@angular/core';
import * as TextConstants from '../../../../constants/text.constants';
import { ROUTE_ICONS_CONFIG, RouteItemIcon } from './configuration/route-icons-configuration';
import { Router } from '@angular/router';
import { AuthService } from '../../../../services/auth.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrl: './admin.component.scss',
})
export class AdminComponent implements OnInit {
  SIDENAV_CONFIGURATION = ROUTE_ICONS_CONFIG;
  textConstants = TextConstants;
  userRole = '';

  constructor(private router: Router, private authService: AuthService) {}

  ngOnInit(): void {
    this.router.events.subscribe(() => {
      var routerUrl = this.router.url;

      const sortedRoutes = this.SIDENAV_CONFIGURATION.slice().sort((a, b) => b.route.length - a.route.length);
      const matchedUrl = sortedRoutes.find((x) => routerUrl.startsWith(x.route)) ?? this.SIDENAV_CONFIGURATION.find((x) => x.route === 'admin')!;

      this.markAsActive(matchedUrl);
    });

    this.authService.setUserData();
    this.userRole = this.authService.userRole;

    document.getElementsByClassName('mat-drawer-inner-container')[0].classList.add('app-scroll');
  }

  isHidden(routeItem: RouteItemIcon): boolean {
    return !routeItem.role.includes(this.userRole);
  }

  applyClass(routeItem: RouteItemIcon): void {
    routeItem.hovered = true;
  }

  removeClass(routeItem: RouteItemIcon): void {
    routeItem.hovered = false;
  }

  goToHomePage() {
    this.router.navigate(['admin']);
  }

  private markAsActive(routeItem: RouteItemIcon) {
    this.SIDENAV_CONFIGURATION.forEach((x) => (x.active = false));

    routeItem.active = true;
  }
}
