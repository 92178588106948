import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthComponent } from './pages/auth/auth.component';
import { authGuard } from './guards/auth.guard';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AdminComponent } from './submodules/admin/pages/admin/admin.component';
import { TeamMemberComponent } from './submodules/team-member/pages/team-member/team-member.component';
import { AuthWsFedComponent } from './submodules/shared/auth-ws-fed/auth-ws-fed.component';
import { GetAccessComponent } from './submodules/shared/get-access/get-access.component';
import { adminsGuard } from './guards/admins.guard';
import { activeUserGuard } from './guards/active-user.gurad';
import { PasswordResetComponent } from './pages/password-reset/password-reset.component';
import { anonymousGuard } from './guards/anonymous-guard';
import * as TextConstants from './constants/text.constants';
import { GuestComponent } from './submodules/guest/pages/guest/guest.component';

const routes: Routes = [
  { path: '', redirectTo: '', pathMatch: 'full' },
  {
    path: '',
    component: GuestComponent,
    canActivate: [anonymousGuard],
    loadChildren: () => import('./submodules/guest/guest.module').then((m) => m.GuestModule),
  },
  {
    path: TextConstants.APP_URL_ROUTE_TEAM_MEMBER_HOME,
    component: TeamMemberComponent,
    canActivate: [authGuard],
    loadChildren: () => import('./submodules/team-member/team-member.module').then((m) => m.TeamMemberModule),
  },
  {
    path: 'ws-fed',
    component: AuthWsFedComponent,
  },
  {
    path: 'auth',
    component: AuthComponent,
  },
  {
    path: 'admin',
    canActivate: [authGuard, adminsGuard, activeUserGuard],
    component: AdminComponent,
    loadChildren: () => import('./submodules/admin/admin.module').then((m) => m.AdminModule),
  },
  {
    path: 'password-reset',
    component: PasswordResetComponent,
  },
  {
    path: 'get-access',
    component: GetAccessComponent,
  },
  { path: 'access-denied', component: AccessDeniedComponent },
  { path: '**', component: ErrorPageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
