<mat-drawer-container class="sidenav-wrapper">
  <mat-drawer mode="side" opened class="sidenav">
    <div class="navigation-wrapper">
      @for (routeItem of SIDENAV_CONFIGURATION; track routeItem) { @if (!isHidden(routeItem)) {
      <button
        class="icon-button"
        [ngClass]="{ active: routeItem.active, hovered: routeItem.hovered }"
        [routerLink]="[routeItem.route]"
        (mouseenter)="applyClass(routeItem)"
        (mouseleave)="removeClass(routeItem)"
      >
        <img class="icon-button-image" [src]="routeItem.active || routeItem.hovered ? routeItem.activeIconUrl : routeItem.iconUrl" alt="Nav Icon" />
      </button>
      } }
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <router-outlet />
  </mat-drawer-content>
</mat-drawer-container>
