import { CanActivateFn, Router } from '@angular/router';
import { AuthService } from '../services/auth.service';
import { inject } from '@angular/core';

import * as TextConstants from '../constants/text.constants';

export const anonymousGuard: CanActivateFn = () => {
  const authService = inject(AuthService);
  const router = inject(Router);

  if (authService.isLoggedIn) {
    return router.createUrlTree([`/${TextConstants.APP_URL_ROUTE_TEAM_MEMBER_HOME}`]);
  }

  return !authService.isLoggedIn;
};
