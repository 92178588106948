import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { CookieService } from 'ngx-cookie-service';
import { ErrorPageComponent } from './pages/error-page/error-page.component';
import { AccessDeniedComponent } from './pages/access-denied/access-denied.component';
import { SharedModule } from './submodules/shared/shared.module';
import { AuthInterceptor } from './interceptors/auth.interceptor';
import { AdminComponent } from './submodules/admin/pages/admin/admin.component';
import { EConfirmationDialogComponent } from './components/e-confirmation-dialog/e-confirmation-dialog.component';

@NgModule({
  declarations: [AppComponent, ErrorPageComponent, AccessDeniedComponent, AdminComponent, EConfirmationDialogComponent],
  imports: [BrowserAnimationsModule, BrowserModule, AppRoutingModule, SharedModule, HttpClientModule],
  providers: [CookieService, { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
