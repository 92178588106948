import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, throwError } from 'rxjs';
import { AuthService } from '../services/auth.service';

import * as TextConstants from '../constants/text.constants';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthInterceptor implements HttpInterceptor {
  textConstants = TextConstants;

  constructor(private authService: AuthService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();

    const tokenExpiration = this.authService.tokenExpiration;
    var currentTime = new Date();
    if (tokenExpiration < currentTime) {
      window.location.href = `${environment.baseUrl}/api/auth/authorize?returnUrl=/`;
      return throwError(() => new Error('Token expired'));
    }

    const clonedRequest = request.clone({
      headers: request.headers.set('Authorization', `Bearer ${token}`),
    });

    return next.handle(clonedRequest).pipe(
      catchError((httpErrorResponse: HttpErrorResponse) => {
        let errorMessage = this.textConstants.UNKNOWN_ERROR;

        if (httpErrorResponse.error instanceof ErrorEvent) {
          errorMessage = `Error: ${httpErrorResponse.error.message}`;
          
        } else {
          errorMessage = httpErrorResponse.error;
          
          
          if (httpErrorResponse.error.status === 401 || httpErrorResponse.error.status === undefined) {
            window.location.href = `${environment.baseUrl}/api/auth/authorize?returnUrl=/`
          }
        }

        return throwError(() => errorMessage);
      })
    );
  }
}
