import { Component } from '@angular/core';
import { AuthService } from './services/auth.service';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';
import { BrandingService } from './services/branding.service';
import { WelcomeFilesService } from './services/welcome-files.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss',
})
export class AppComponent {
  title = 'E-LearningPlatformApp';

  showGreeting: boolean = true;
  userName = '';
  userEmail = '';
  userId = '';
  userFirstName = '';
  userLastName = '';
  userRole = '';
  isInAdminPanel: boolean = false;

  constructor(
    private router: Router,
    private authService: AuthService,
    private brandingService: BrandingService,
    private mediaFileService: WelcomeFilesService
  ) {}

  ngOnInit(): void {
    this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
      this.isInAdminPanel = this.router.url.includes('admin');
      this.showGreeting = this.router.url.includes('error');
      this.showGreeting = this.router.url.includes('access-denied');
    });

    this.authService.setUserData();
    this.userEmail = this.authService.userEmail;
    this.userId = this.authService.userId;
    this.userFirstName = this.authService.userFirstName;
    this.userLastName = this.authService.userLastName;
    this.userName = this.authService.userName;
    this.userRole = this.authService.userRole;

    this.brandingService.getBranding().subscribe((branding) => {
      const favicon = document.getElementById('favicon');

      if (favicon !== null) {
        if (branding.favIconFileId !== null) {
          this.mediaFileService.getByIdAndUpdateUrl(branding.favIconFileId, 'welcomefiles').subscribe((info) => {
            favicon.setAttribute('href', info.url);
          });
        } else {
          favicon.setAttribute('href', 'assets/images/favicon.png');
        }
      }
    });
  }
}
